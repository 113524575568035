.top-search {
    display: flex;
    justify-content: space-between;
   gap: 30px;
}

.client-detail {
    display: flex;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.client-logo {
    width: 69px;
    height: 67px;
    border-radius: 10px;
    border: 0.25px solid var(--Color1, #5C5C5C);
}

.client-data {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 150px;
}

.client-name {
    color: #505050;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.client-type {
    width: max-content;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contract-background {
    color: #1B7D78;
    background: rgba(27, 125, 120, 0.20);
}

.client-type.contract-background {
    color: #1B7D78;
    background: rgba(27, 125, 120, 0.20);
}


.client-type.variable-background {
    color: #F00;
    background: rgba(255, 0, 0, 0.20);
}

.contact-details,
.contact-email,
.contact-phone {
    display: flex;
    align-items: start;
    gap: 10px;
    margin-bottom: 12px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact-details,
.contact-detile {
    color: #000;
    text-wrap: wrap;
    /* overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}

.contact-email,
.contact-map {
    color: #505050;
}

.contact-phone {
    color: #8C8C8C;
}

.contact-icon {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
}

.client-actions {
    width: 24px;
    height: 24px;
    color: #505050;
}

.client-logoo {
    text-align: center;
    width: 380px;
    height: 143px;
    border-radius: 6px;
    border: 0.5px solid #C8C8C8;
}

.client-info {
    display: flex;
    gap: 20px;
    margin: 16px 0px;
}

.filter-item {
    position: relative;
}

.filter-icons {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    min-width: 120px;
    /* min-height: 99px; */
    flex-shrink: 0;
    position: absolute;
    z-index: 4
}

.customer-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contact-detile,
.contact-map,
.contact-rating {
    gap: 10px;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
}

.contact-icons {
    width: 18px;
    height: 18px;
}

.data-text {
    width: 350px;
}

.support-icons {
    border: 1px solid #8C8C8C;
    border-radius: 5px;
    width: 22px;
    height: 22px;
    color: #1B7D78;
    padding: 5px;
}

.client-options {
    width: 24px;
    height: 24px;
}

.logo-image {
    height: 134px;
    width: 134px
}

.contact-rating {
    color: #8C8C8C;
    display: flex;
    /* justify-content: space-between; */
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 15px;
}

.client-form .nav-tabs .nav-link.active {
    color: #1B7D78;
    border-bottom: 5px solid #1B7D78;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.client-form .nav-tabs .nav-link {
    margin-bottom: 0;
    border: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    color: #5C5C5C;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contact-emails {
    width: fit-content;
}