.order-header-main {
    border: 1px solid #DDD;
}

.orders-inProgress {
    display: flex;
    gap: 30px;
    align-items: center;
    cursor: pointer;
}

.orders-inProgress p {
    margin-top: none;
    margin-bottom: none;
}

.Manage-Orders {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.Manage-Orders h6 {
    color: #5C5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.price-border {
    border-bottom: 1px solid #DDD;
}

.orders-new {
    border-right: 1px solid #DDD;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.orders-inprogres-main {
    text-align: center;
    border-right: 1px solid #DDD;
}

.orders-inprogres-sub {
    padding: 10px;
    border-bottom: 1px solid #DDD;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.orders-inprogres-sub h6 {
    color: #5C5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.search-input {
    display: flex;
    align-items: center;
    border: 1px solid #DDD;
    margin-top: 10px;
    text-align: center;
    padding: 5px;
    gap: 10px
}

/* .input-with-icon {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
} */

.icon {
    margin-right: 8px;
}


.input-control:focus {
    border: none;

}

.name-orders {
    width: 100px;
    height: 20px;
    border-radius: 5px;
    background: rgba(27, 125, 120, 0.20);
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* text-align: center; */
    font-size: 12px;
    padding-left: 3px;
    font-weight: 400;
    color: '#1B7D78';
}

.store-orders {
    width: 100px;
    height: 20px;
    border-radius: 5px;
    background: rgba(0, 0, 255, 0.20);
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: blue;
    padding-left: 3px;
}

.out-of-stock {
    width: 100px;
    height: 20px;
    border-radius: 5px;
    background: rgba(255, 123, 0, 0.2);
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: red;
    padding-left: 3px;
}

.order-data {
    margin-top: 12px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    border: 1px solid #ddd;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.order-act {
    height: calc(100vh - 50px);
    overflow-y: auto;
    padding-left: 0;
}

.client-address {
    height: 20px;
    width: 200px;
    overflow-y: hidden;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.order-act::-webkit-scrollbar {
    display: none;
}

.chart-size{
    height: 400px;
}

.chart-size canvas{
    display: block;
    box-sizing: border-box;
    height: 400px;
    width: 800px;
    margin: auto;
}

.ordrs-bottom:hover {
    background-color: #FFD2F4;
    cursor: pointer;
}

.ordrs-bottom {
    border: 1px solid #DDD;
    padding: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-family: 'Inter';
}

.ordrs-bottom.active {
    background-color: rgba(27, 125, 120, 0.20);
    font-family: 'Inter';
    cursor: pointer;
    border: 1px solid #DDD;
    padding: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ordrs-total {
    border-top: 1px solid #ddd;
    padding: 18px;
}

.ordrs-tota-button {
    display: flex;
    justify-content: end;
    padding: 5px;
}

.oreder-precessed p {
    color: #1B7D78;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.oreder-precessed {
    display: flex;
    gap: 30px;
    justify-content: center;
}

.order-actions {
    border-right: 1px solid #DDD;
    display: flex;
    flex: 1;
}

.order-header header {
    display: none;
}

.order-header {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.border-order {
    padding-left: 12px;
    margin-bottom: 20px;
}

.itm-table {
    display: flex;
    align-items: center;
    gap: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 300px;
}

.table-itm-img {
    width: 46px;
    height: 46px;
}

.order-money-items {
    color: #6F7588;
    font-size: 16px;
    margin-top: 8px;
    display: flex;
    /* gap: 22px; */
    justify-content: space-between;
    align-items: center;
}

.add-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.add-header:hover{
    background-color: #eee;
}

.delivery-agent {
    border: 1px solid #DDD;
    padding: 9px;
    text-align: center;
    border-radius: 4px;
    color: #5C5C5C;
    font-size: 13px;
    font-weight: 500;
}

.subtotal {
    padding: 20px;
    border-bottom: 1px solid #8C8C8C;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.total {
    padding: 20px;

}

.rowBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rowCenter {
    display: flex;
    align-items: center;
}

.product-stock {
    width: 192px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 56px;
}

.product-name {
    width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
}

.delete-icon {
    border: 1px solid rgb(27, 125, 120);
    border-radius: 50%;
    padding: 1px;
    cursor: pointer;
}

.product-main {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #8C8C8C;
    gap: 50px;
}

.product-main:last-child {
    border-bottom: none;
}

.search-input {
    display: flex;
    align-items: center;
}

.search-input input {
    border: none;
    outline: none;
    flex: 1;
}

.img-contaoner-modal {
    width: 56px;
    height: 56px;
    background-color: rgba(27, 125, 120, 0.15);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-contaoner-modal>img {
    width: 46px;
    height: 46px;
}

.product-list {
    max-height: 50vh;
    overflow-y: scroll;
    margin-top: 20px;
}

.product-list::-webkit-scrollbar {
    display: none;
}

.product-info {
    display: flex;
    width: 70%;
    gap: 13px;
    justify-content: space-between;
    align-items: center;
}

.item-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #1B7D78;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-qty {
    width: 107px;
    border: 1px solid #1B7D78;
    border-radius: 4px;
    outline: none;
    height: 37px;
    padding: 10px;
}

.activeCalss-order {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1B7D78;
    border-bottom: 5px solid #1B7D78;
    box-sizing: border-box;
    padding: 7px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: center;
}

.Class-order {
    padding: 7px;
    display: flex;
    align-items: center;
    color: #5C5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    gap: 4px
}

.new-Class-order {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #5C5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.btn-pink {
    background-color: #1B7D78;
    color: white;
}

.btn-pink:hover {
    border: 1px solid #A72B89;
}

.btn-black:hover {
    border: 1px solid #5C5C5C;
}

.btn-black {
    background-color: #5C5C5C;
    color: white;
}

.butto-order {
    display: flex;
    align-items: center;
    gap: 20px;
}

.button-border {
    border-right: 1px solid #DDD;
}

.icons-ordr-size {
    width: 30px;
    height: 30px;
    color: #5C5C5C;
    border: 1px solid #5C5C5C;
    padding: 2px;
    margin-right: 20px;
    cursor: pointer;
}

.money-data {
    display: flex;
    justify-content: end;
    margin-top: 31px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.details-money {
    display: flex;
    justify-content: space-between;
    width: 250px;
    font-size: 16px;
    line-height: 20px;
}

.store-details-roder {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.details-money-total {
    display: flex;
    justify-content: space-between;
    width: 250px;
    font-size: 20px;
    font-weight: 600;
    padding-top: 9px;
    padding-bottom: 9px;
}

.modify-accept {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.no-orders {
    display: flex;
    align-items: center;
    height: 212px;
    background-size: 100% 100%;
    background-image: url('https://merchantdash.dunzo.in/static/media/zero-state.4bbeb9fd.svg');
}

.para-table-nodata {
    color: #9FA3AF;
    font-size: 32px;
}

.button-text-order {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.button-orders-image {
    background-color: white;
    padding: 8px 16px 8px 16px;
    border-color: #6F7588;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.75;
    border-radius: 5px;
}

.button-orders-image:hover {
    background-color: #6f75881a;
}

.image-name-order {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 3px;
}

.zero-padding {
    padding-left: 10px !important;
    padding-right: 0 !important;
    margin-top: 10px;
    border-radius: 0px !important;
}

.success-image {
    width: 22px;
    height: 20.93px;
}

.length-data {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    color: white;
    flex-shrink: 0;
    background: #1B7D78;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lenght-Inactive {
    border-radius: 3px;
    background: #A2A2A2;
    width: 20px;
    height: 20px;
    color: white;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-button {
    margin-bottom: 18px;
}

.create-ord-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-top: 20px;
}

.details-sub {
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 6fr;
}

.m_20 {
    margin-bottom: 20px;
}

.create-ord-left {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #5C5C5C;
}

.create-ord-right {
    display: flex;
    gap: 30px;
    align-items: center;
}

.create-ord-card {
    border-radius: 8px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
}

.ord-items {
    max-height: 40vh;
    overflow-y: scroll;
}

.create-card-top {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    border-bottom: 1px solid #8C8C8C;
    height: 69px;
    align-items: center;
}

.actions {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.f_14_500 {
    font-weight: 500;
    font-size: 14px;
}

.f_14_400 {
    font-weight: 400;
    font-size: 14px;
}

.f_24_400{
    font-weight: 400;
    font-size: 24px;
}

.f_12_400 {
    font-weight: 400;
    font-size: 12px;
}

.f_13_600 {
    font-size: 13px;
    font-weight: 600;
}

.f_13_500 {
    font-size: 13px;
    font-weight: 500;
}

.f_13_400 {
    font-size: 13px;
    font-weight: 400;
}

.rowAligned {
    display: flex;
    align-items: center;
}

.rowEndCenter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gap-20 {
    gap: 20px;
}

.gap-10 {
    gap: 10px;
}

.pointer{
    cursor: pointer;
}

.w_130 {
    width: 130px;
}

.w_100 {
    width: 100px;
}

.delivery-client {
    height: 145px;
    margin: 20px auto;
    border-right: 1px solid #C8C8C8;
    padding-right: 38px;
}

.ellipsis {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.flex-1 {
    flex: 1
}

.img-client {
    width: 71px;
    height: 71px;
    border-radius: 10px;
    border: 1px solid #5C5C5C;
}

.icon-container-16 {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #8C8C8C;
    border-radius: 4px;
}

.column-h-71 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 71px;
}

.orders-margin {
    padding-top: 20px;
}

.table-image-main {
    width: 56px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: rgba(27, 125, 120, 0.15);
    padding: 6px 5px;
}

.data-table-extensions>.data-table-extensions-action>button.print {
    background-image: url('./images/pdficon.png') !important;
    background-size: contain;
    width: 24px;
    margin-right: 10px;
}

.data-table-extensions>.data-table-extensions-action>button.download {
    background-image: url('./images/excelicon.png') !important;
    background-size: contain;
    width: 24px;
}

.data-table-extensions>.data-table-extensions-action>button.download {
    height: 50px;
    display: flex;
    align-items: center;
}

.report-icons {
    cursor: pointer;
    color: black;
}

.report-icons.disable{
    cursor: not-allowed;
    color: #C8C8C8;
}

.report-icons:hover {
    background-color: #DDD;
    border-radius: 5px;
}