.switch{
    position: relative;
    display: inline-block;
    width: 22px;
    height: 13px;
    background-color: #939393;
    border-radius: 10px;
}

.switch::after{
    content: '';
    position: absolute;
    top: 0px;
    left: -1px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15), -1px -1px 10px 0px rgba(0, 0, 0, 0.13);
    transition: all 0.1s linear;
}

input[type="checkbox"]:checked + .switch:after{
    translate: 10px 0;
    background-color: #ffffff;
}

input[type="checkbox"]:checked + .switch{
    background-color: #0B82A2;
}

.toggle-switch[type="checkbox"]{
    display: none;
}