@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&display=swap');

h1,h2,h3,h4,h5,h6,p,ul,li{
    font-family: 'Inter', sans-serif;
}
.form-group {
    margin-bottom: 16px;
}
.form-group label{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 8px;
}
span.required-star {
  color: red;
}
.ari-radio{
  width: 30px;
  height: 30px
}
span.required-star {
  color: red;
}
.ari-radio-label {
  display: flex;
  align-items: center;
  gap: 20px;
}

p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.form-group.has-icon {
    position: relative;
}
img.inputicon {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
img.inputicon1 {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width:20px;
  height:15px;
}
.checkmark__circle{
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}
.checkmark{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 0px auto 20px;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}.checkmark__check{transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}
.successmsg, .errormsg{
    text-align: center;
}
@keyframes stroke{100%{stroke-dashoffset: 0}}
@keyframes scale{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}
@keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #7ac142}}


.crossmark__circle.animateElement {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #ff0000;
    fill: none;
   animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .crossmark.animateElement {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #ff0000;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #ff0000;
    animation: fillred .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .crossmark__check.animateElement {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  .cross__path.animateElement {
    stroke: #ffffff;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    transform-origin: 50% 50% 0;
  }
  .cross__path--right.animateElement {
    animation: 0.3s ease 0.8s normal forwards 1 running stroke;
  }
  .cross__path--left.animateElement {
    animation: 1s ease 0.8s normal forwards 1 running stroke;
  }
  @keyframes fillred {
    100% {
      box-shadow: inset 0px 0px 0px 30px #ff0000;
    }
    
  }
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

.btn-block {
  width: 100%;
}
img.form-edit-img {
  margin-left: 10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
@media only screen and (max-width: 991.98px) {
}
@media screen and (max-width: 767.98px) {
}